
import { Component, Vue } from "vue-property-decorator";

type Messages = {
  success?: null | string;
  errors: null | object;
};

@Component({
  components: {
    Alert: () => import("@/app/modules/_global/components/Alert.vue"),
    Form_: () => import("@/app/modules/profile/components/ChangePasswordForm.vue")
  }
})
export default class ChangePasswordPage extends Vue {
  passwords: object = {};

  messages: Messages = {
    success: null,
    errors: null
  };

  get appData() {
    return this.$store.getters["app/data"];
  }

  updatePassword() {
    this.$store
      .dispatch("profile/updatePassword", this.passwords)
      .then(({ data }) => {
        this.scrollTop();

        this.passwords = {};
        this.messages = {
          success: data.message,
          errors: null
        };
      })
      .catch(error => {
        this.messages = {
          success: null,
          errors: error.response.data.errors
        };
      });
  }

  scrollTop() {
    const card = document.getElementById("card");
    if (card) card.scrollIntoView({ behavior: "smooth" });
  }
}
